import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import FilteredCard from './components/FilteredCard';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterCheckedData, handleFilter } from '../../features/filter/FilterSlice';
import { KeyboardArrowDown } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MobileFilter from './components/MobileFilter';
import SliderComponent from './components/SliderComponent';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    sx={{
      width: '100%',
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme, widthNumber }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    padding: 20,
    width: widthNumber,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
     
    },
  },
}));

const Filter = () => {
  const theme = useTheme();
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const checkedFilter = useSelector(getFilterCheckedData);
  const [filter, setFilter] = useState({
    'Ne Zhvillim': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Object': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Floor': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Square': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
  })

  const filterLabelStyle = {
    fontSize: isSmallDev ? "18px" : "1.4rem",
    fontFamily: "Montserrat",
    color: 'white'
  };
  const infoTextStyle = {
    fontSize: isSmallDev ? "12px" : "1.1rem",
    fontFamily: "Montserrat",
    color: 'white'
  };
  const handleFilterState = (e, s) => {
    switch(s){
      case 'object':
        setFilter({
          ...filter,
          "Object" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'devState':
        setFilter({
          ...filter,
          "Ne Zhvillim" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'floor':
        setFilter({
          ...filter,
          "Floor" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'square':
        setFilter({
          ...filter,
          "Square" : {
            opened: true,
            anchorEl: e.target,
          }
        })
        break;
    }
  }
  const handleObjectFilter = (name) => {
    if(checkedFilter.length < 1) {
      dispatch(
        handleFilter({
          name: name,
          isChecked: true,
        }));
        return;
    }
    if(!checkedFilter.find((item) => 
      item.name === name
    )) {
      dispatch(
        handleFilter({
          name: name,
          isChecked: true,
        }));
        
        return;
    }
    checkedFilter.forEach((item) => {
      if(item.name === name) {
        if(item.isChecked){
          dispatch(
            handleFilter({
              name: name,
              isChecked: false,
            }));
            if(checkedFilter.some((item) => item.isChecked === false)){
              if(checkedFilter.find((item) => item.name === 'all') && !name.includes('Objekti')){
                dispatch(
                  handleFilter({
                    name: 'all',
                    isChecked: true,
                  }));
              }
            }
            return;
        }
        else {
          dispatch(
            handleFilter({
              name: name,
              isChecked: true,
            }));
            return;
        }
      }
      else {
          return;
      }
    })
   
  }
  return (
   <React.Fragment>
    {isSmallDev ? <MobileFilter /> : 
     <Box width={"100%"} overflow={"hidden"}>
     <Box
       height={checkedFilter?.length < 1 && "0px"}
       display={"flex"}
       flexWrap={"wrap"}
       columnGap={1}
     >
       {checkedFilter?.map((item, index) => {
         if (item.isChecked === true && item.name !== 'allFloor' && item.name !== 'allObj') {
           return <FilteredCard key={index} title={item.name} />;
         }
       })}
     </Box>
     <Grid container mt={1} spacing={3}>
       <Grid item xs={12} sm={6} md={3} lg={3}>
         <Box
           sx={{
             width: "100%",
             backgroundColor: "rgba(0, 0, 0, 0.0)",
             borderBottom: '1px solid black',
             cursor: "pointer",
             display: "flex",
             position: "relative",
             justifyContent: "space-between",
             color: "black",
             alignItems: "center",
             borderRadius: '5px',
             py: 1,
           }}
           onClick={(e) => {
             handleFilterState(e, "object");
           }}
         >
           <Typography sx={{ ...filterLabelStyle, color: 'black' }}>Objekti</Typography>
           <KeyboardArrowDown
           />
         </Box>
       </Grid>
       <Grid item xs={12} sm={6} md={3} lg={3}>
         <Box
           sx={{
             width: "100%",
             backgroundColor: "rgba(0, 0, 0, 0.0)",
             borderBottom: '1px solid black',
             cursor: "pointer",
             display: "flex",
             position: "relative",
             justifyContent: "space-between",
             color: "black",
             alignItems: "center",
             borderRadius: '5px',
             py: 1,
           }}
           onClick={(e) => {
             handleFilterState(e, "devState");
           }}
         >
           <Typography sx={{ ...filterLabelStyle, color: 'black' }}>Faza Ndertimit</Typography>
           <KeyboardArrowDown
           />
         </Box>
       </Grid>
       <Grid item xs={12} sm={6} md={3} lg={3}>
         <Box
           sx={{
             width: "100%",
             backgroundColor: "rgba(0, 0, 0, 0.0)",
             borderBottom: '1px solid black',
             cursor: "pointer",
             display: "flex",
             position: "relative",
             justifyContent: "space-between",
             color: "black",
             alignItems: "center",
             borderRadius: '5px',
             py: 1,
           }}
           onClick={(e) => {
             handleFilterState(e, "floor");
           }}
         >
           <Typography sx={{ ...filterLabelStyle, color: 'black' }}>Kati</Typography>
           <KeyboardArrowDown
           />
         </Box>
       </Grid>
       <Grid item xs={12} sm={6} md={3} lg={3}>
         <Box
           sx={{
             width: "100%",
             backgroundColor: "rgba(0, 0, 0, 0.0)",
             borderBottom: '1px solid black',
             cursor: "pointer",
             display: "flex",
             position: "relative",
             justifyContent: "space-between",
             color: "black",
             alignItems: "center",
             borderRadius: '5px',
             py: 1,
           }}
           onClick={(e) => {
             handleFilterState(e, "square");
           }}
         >
           <Typography sx={{ ...filterLabelStyle, color: 'black' }}>Siperfaqja</Typography>
           <KeyboardArrowDown
           />
         </Box>
       </Grid>
     </Grid>
     <StyledMenu
       widthNumber={350}
       id="devState"
       anchorEl={filter["Ne Zhvillim"].anchorEl}
       open={filter["Ne Zhvillim"].opened}
       onClose={() => {
         setFilter({
           ...filter,
           "Ne Zhvillim": { ...filter["Ne Zhvillim"], opened: false },
         });
       }}
     >
       <Box>
         <FormGroup>
           <FormControlLabel
             label="Ne Zhvillim"
             control={
               <Checkbox
                 onChange={(e) => {
                   dispatch(
                     handleFilter({
                       name: e.target.name,
                       isChecked: e.target.checked,
                     })
                   );
                 }}
                 name="Ne Zhvillim"
                 checked={
                   checkedFilter.find(
                     (item) => item.name === "Ne Zhvillim"
                   )
                     ? checkedFilter.find(
                         (item) => item.name === "Ne Zhvillim"
                       ).isChecked
                     : false
                 }
               />
             }
           />
         </FormGroup>
       </Box>
     </StyledMenu>
     <StyledMenu
       anchorEl={filter["Object"].anchorEl}
       widthNumber={500}
       open={filter["Object"].opened}
       onClose={() => {
         setFilter({
           ...filter,
           Object: { ...filter["Object"], opened: false },
         });
       }}
     >
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 500 400" xmlSpace="preserve">
<image width="7680" height="4320" xlinkHref="https://afarizmi.international-residence.com/apartmentImg/Situacioni.png"  transform="matrix(7.072498e-02 0 0 7.072498e-02 -43.1679 53.617)">
</image>
<path className="object-filter-select" d="M46.6,140.3l-6.6,16.4l1.7,0.8L35,174.6l-1.5-0.6l-1.6-0.3l-6.5,16.6l6.6,2.2l-2,5l0.4,0.2l-1.2,3l21.4,8.4
	c0,0,4.7,0.2,4.8,0.2s0.6-2,0.6-2l6.6,2.6l-0.3,0.7l6.1,2.5l-2.4,6l28.5,11.5l1.3-0.3l18.3-47.1l1.9,0.2l-5.4-2.2l7.1-20.3
	l-31.7-12.6l1.9-4.4l4-10.5l-3.1-1.6l-22-11.8l-5,12.4l-3.9-1.5l-2.7,7.1l-3.1-1.1l-1.9,4.9L46.6,140.3z"/>
<polygon className="object-filter-select" points="134.1,105.3 134.1,106.7 135.4,108.8 135.4,110.1 120.9,110.1 126.7,135.1 128.9,136.9 144,136.3 
	144,143.5 139.9,143.5 139.7,162.6 141.5,163.3 144.7,163.3 144.7,166.1 148.3,167.8 165.4,167.8 165.4,164.3 172.8,164.2 
	172.8,163.4 174.6,163.5 174.6,164 175.3,164 175.3,167.8 183.1,167.8 183.1,174.3 184.3,175.4 216,175.7 215.4,174.7 215.8,147 
	216.8,146.9 216.8,105.7 211.4,105.6 211.4,102.6 199.6,102.4 199.3,101.3 181.3,101.1 181.3,105.2 173.7,105.3 173.6,105.8 
	165.7,105.8 163.3,101.9 145.6,101.6 145.5,105.3 "/>
<path className="object-filter-select" d="M245.8,102.2l-0.6,3.5v2.3h-4.9l-0.3,2.9c0,0-0.2,17.6-0.2,17.6c0.1,0,3.4,0,3.4,0s0,6.7,0,6.7c0.1,0,2,0,2,0
	v0.8h-1.6l0,1h-0.3l0,6.7h-3.1l-0.4,1.9l-0.1,17.3l5.3,0.1l0,5.4l16.9,0.1l1.1-1.4v-2.5l7.1,0v-0.5l0.7,0v-0.9l1.3,0.1v1.6l7.9,0.1
	v3.3l16.9,0.2l2.6-1.4v-3.6l2.3,0l2.7-1.6v-17.5l-3.8,0v-7.5l-0.7,0l0-1.7l0.2,0l0-1h1v-5.7l1.3,0l2.6-2.9l0.1-17.6l-5.2-0.1l0-0.7
	l-0.7,0v-4h-17.7l-2,3.8l-8.3,0.1v-0.6l-7.7-0.1l0-4H245.8z"/>
<path className="object-filter-select" d="M346.2,102.4l-4.3,3.8v2.3h-2.1l-3.9,3.4v16.9h4.8l-0.1,6.8h1.2v1.5l-0.4,0.2l-0.1,0.8l-1.6,0.2l0.1,6l-3.4,1.8
	l-0.1,16.9l4.7,0.2v5.3l16.7,0.3l4.7-1.4v-2.6l5.2,0.1v-0.5l1.2,0v0.7l7.9,0v3.3c0,0,17.1,0.2,17.2,0.1c0.1,0,5.7-1.4,5.7-1.4
	l0.1-4.1l4.2-1v-12.9l1,0l3.5-1.1l0.2-41.2l-9.8-0.1v-3.6l-17.6-0.1l-5.4,3.8l0,0.3l-4.9,0l0-0.6h-7v-4.1L346.2,102.4z"/>
<polygon className="object-filter-select" points="144,186.8 142.7,191.6 136.9,190.4 132.5,206.8 133.9,207.3 132.1,213.5 130.2,213 128,213.1 
	124.9,224.5 124.2,224.7 121.4,234.8 121.9,235.1 121.4,237 122.1,237.2 121.7,238.8 122.3,239.1 121.8,240.8 128.1,242.7 
	126.9,246.8 143.3,251.4 143.9,251.1 150.6,252.8 151.1,251 160.8,253.6 160,256.4 176.6,261 177.5,260 178.4,256.4 182.8,257.6 
	183.6,256.6 193.6,259.3 203.3,224.3 195.9,222.1 200,207.5 200.7,207.5 202.8,199.4 170.8,190.7 168.5,199 159,196.3 160.4,191.3 
	"/>
<polygon className="object-filter-select" points="236.6,204.6 207.3,263.4 216.1,267.9 215.9,269 220.2,271.3 218.4,274.6 218.4,275.6 233.4,283.4 
	234.7,281.1 249.6,288.7 249.1,290 249.6,291.3 264.6,299 266.8,294.8 272,297.3 272.7,295.9 273.2,296.1 276.3,292.8 280.8,283.7 
	280.6,283 285.8,272.4 285,271.4 282.2,270 285,264.4 287.5,265.6 295.1,250.4 290,247.6 292.4,243.1 292.1,242.8 295.3,236.2 "/>
<path className="object-filter-select" d="M362.2,208.9l3.1,0.3l37.4,54.4l-5.5,3.8l0.2,0.4l-0.6,0.3l0.7,1.1l-0.6,0.3l0.6,1l-4.7,3.4l2.3,3.4l-14,9.7
	l-1.4-0.7l-0.5-0.8l-0.4,0.3l-1.2-1.7l-8.1,5.6l0.4,0.5l-5.6,3.7l2,2.9l-14.1,9.7l-1.9-1.5l-1-1.5c0,0-3.6,2.4-3.6,2.4
	s-2.5-2.2-2.5-2.2l-9.4-13.8l0.7-0.4c0,0-5.2-7.4-5.2-7.4c0,0,2.8-2,2.8-2l-4.1-6.4l-3.4,2.3l-0.9-0.6l-9.4-13.9l3.5-2.5l-8.4-11.9
	L362.2,208.9z"/>
<path className="object-filter-select" d="M433.1,172.9l-13.6,9.4l2,3.4l-12.3,8.5l-1.5-2.1l-1.6,0.1l-0.3,0.2l-2.2-2.8l-2.6,0.1l-19.8,13.6l4.1,5.5
	l-4.9,3.6l30.5,44.3l8.7-6c0,0,0.3,0.4,0.4,0.4c0.1,0,3,0.6,3,0.6l1.7-1.1l1.1,1.4c0,0,1.5,0.4,1.6,0.4s0.7-0.4,0.7-0.4l0.7,0.7
	l14-9.5l-1.4-1.8l4-2.7l-1.1-1.6l8.6-6l1,1.6l0.5-0.1l0.6-0.4l0.5,0.7l1.9,0.2l13.9-9.6l-2.4-3.3l5.4-4l-0.7-1l1.1-0.9l-0.5-0.8
	l1-0.8l-0.7-1.2l1.1-0.8l-5.9-8.5H468l-6.7-9.6l-1.2,0.7l-4.9-6.9l-1.9,1.1l-9.9-13.9l-1.9,0.3l-0.6,0.4l-0.3-0.3h-1.1l-1.5-2
	L433.1,172.9z"/>
</svg>
     </StyledMenu>
     <StyledMenu
       anchorEl={filter["Floor"].anchorEl}
       widthNumber={350}
       open={filter["Floor"].opened}
       onClose={() => {
         setFilter({
           ...filter,
           Floor: { ...filter["Floor"], opened: false },
         });
       }}
     >
       <Box sx={{
         display: 'flex',
         justifyContent: 'space-between'
       }}>
         <Box>
           <FormGroup>
             <FormControlLabel
               label="Përdhesë"
               control={
                 <Checkbox
                   onChange={(e) => {
                     dispatch(
                       handleFilter({
                         name: e.target.name,
                         isChecked: e.target.checked,
                       })
                     );
                   }}
                   name="Përdhesë"
                   checked={
                     checkedFilter.find(
                       (item) => item.name === "Përdhesë"
                     )
                       ? checkedFilter.find(
                           (item) => item.name === "Përdhesë"
                         ).isChecked
                       : false
                   }
                 />
               }
             />
           </FormGroup>
           <FormGroup>
             <FormControlLabel
               label="Suteren"
               control={
                 <Checkbox
                   onChange={(e) => {
                     dispatch(
                       handleFilter({
                         name: e.target.name,
                         isChecked: e.target.checked,
                       })
                     );
                   }}
                   name="Suteren"
                   checked={
                     checkedFilter.find(
                       (item) => item.name === "Suteren"
                     )
                       ? checkedFilter.find(
                           (item) => item.name === "Suteren"
                         ).isChecked
                       : false
                   }
                 />
               }
             />
           </FormGroup>
         </Box>
         <Box>
           <FormGroup>
             <FormControlLabel
               label="Kati 1"
               control={
                 <Checkbox
                   onChange={(e) => {
                     dispatch(
                       handleFilter({
                         name: e.target.name,
                         isChecked: e.target.checked,
                       })
                     );
                   }}
                   name="Kati 1"
                   checked={
                     checkedFilter.find(
                       (item) => item.name === "Kati 1"
                     )
                       ? checkedFilter.find(
                           (item) => item.name === "Kati 1"
                         ).isChecked
                       : false
                   }
                 />
               }
             />
           </FormGroup>
           <FormGroup>
             <FormControlLabel
               label="Bodrum"
               control={
                 <Checkbox
                   onChange={(e) => {
                     dispatch(
                       handleFilter({
                         name: e.target.name,
                         isChecked: e.target.checked,
                       })
                     );
                   }}
                   name="Bodrum"
                   checked={
                     checkedFilter.find(
                       (item) => item.name === "Bodrum"
                     )
                       ? checkedFilter.find(
                           (item) => item.name === "Bodrum"
                         ).isChecked
                       : false
                   }
                 />
               }
             />
           </FormGroup>
         </Box>
       </Box>
     </StyledMenu>
     <StyledMenu
       anchorEl={filter["Square"].anchorEl}
       widthNumber={350}
       open={filter["Square"].opened}
       onClose={() => {
         setFilter({
           ...filter,
           Square: { ...filter["Square"], opened: false },
         });
       }}
     >
       <Typography variant="h6">Siperfaqja</Typography>
       <SliderComponent />
     </StyledMenu>
     
   </Box>}
   </React.Fragment>
  );
}

export default Filter