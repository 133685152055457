import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { getSvgData, handleDetailModalData, handleDetailModalState } from '../../../features/afarizmi/AfarizmiSlice';
import { getFilterCheckedData } from '../../../features/filter/FilterSlice';
import { IconButton } from '@mui/material';
import { Preview } from '@mui/icons-material';
import { mainDarkColor } from '../../../utils/consts';
import { useEffect } from 'react';
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: mainDarkColor,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function TableView() {
  const svgData = useSelector(getSvgData)
  const checkedFilter = useSelector(getFilterCheckedData)
  const dispatch = useDispatch()
  const [sortedArray, setSortedArray] = useState([])

  const handlePreview = (item) => {
    dispatch(handleDetailModalState(true))
    dispatch(handleDetailModalData(item))
  }

  useEffect(() => {
    if(svgData){
      const combined = []
      svgData.forEach((buildings) => {
        buildings.apartmentList?.forEach((item) => {
          combined.push(item)
        })
      })

     setSortedArray(combined)    }
  }, [svgData])

  return (
    <TableContainer component={Paper} sx={{mb: 5}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Lokali</StyledTableCell>
            <StyledTableCell align="right">Objekti</StyledTableCell>
            <StyledTableCell align="right">Kati</StyledTableCell>
            <StyledTableCell align="right">Siperfaqja</StyledTableCell>
            <StyledTableCell align="right">Veprimet</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            sortedArray?.sort((a, b) => parseInt(a.apartmentNumber) - parseInt(b.apartmentNumber)).map((item) =>  {
              if((checkedFilter.some((it) => item.apartmentNumber.includes(it.name.slice(8)) && it.isChecked)) || checkedFilter.some((it) => it.name.includes('all') && it.isChecked)){
               
                return (
                  <StyledTableRow sx={{
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)'
                    }
                  }} onClick={() => {
                    handlePreview(item)
                  }} key={item.id}>
                  <StyledTableCell component="th" scope="row">
                    {item.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {item.apartmentNumber}
                  </StyledTableCell>
                  <StyledTableCell align="right">{item.floor}</StyledTableCell>
                  <StyledTableCell align="right">
                    {item.square?.toFixed(2) + ' m2'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton color='primary' title='Detaje' onClick={() => handlePreview(item)}>
                      <Preview />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
                )
            }              
          }
            
          
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}