import { Box, Typography } from '@mui/material'
import React from 'react'

const InfoCard = ({ image, title, value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: {
          xs: 1,
          md: 2,
        },
        width: {
          xs: '145px'
        }
      }}
    >
        <img style={{objectFit: 'contain'}} src={image} alt="Icon" width="55px" height="55px"/>
        <Typography sx={{
          fontSize: {
            xs: '17px',
            md: '18px'
          }
        }}>{title}</Typography>
        <Typography sx={{
          fontSize: {
            xs: '17px',
            md: '18px'
          }
        }} fontWeight={'bold'}>{value}</Typography>
    </Box>
  )
}

export default InfoCard