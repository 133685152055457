import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const PageLoading = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(99, 99, 99, 0.7)',
        backdropFilter: 'blur(5px)',
        zIndex: 9999,
      }}
    >
      <CircularProgress sx={{color: 'white', opacity: 1}} size={120} />
    </Box>
  );
}

export default PageLoading