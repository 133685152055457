import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/consts";
import { tokenConfig, tokenConfigContentMultipart } from "../auth/AuthSlice";

const url = `${BASE_URL}/api/apartment`;

export const getSvgDataAll = createAsyncThunk(
    'AfarizmiSlice/getall',
    async(a, { rejectWithValue }) => {
        try {
           const res = await axios.get(`${url}/get`);
           return res.data; 
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateIsSold = createAsyncThunk(
    'AfarizmiSlice/updateIsSold'
);

export const getApartmentById = createAsyncThunk();

export const getAllApartmentsById = createAsyncThunk();

export const updateApartment = createAsyncThunk(
    'AfarizmiSlice/updateApartment',
    async(obj, { rejectWithValue }) => {
        try {
            const res = await axios.post(`${url}/update`, obj, tokenConfigContentMultipart());
            return res.data;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)