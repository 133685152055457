import { Apartment, Map, OpenWith, TableRows } from '@mui/icons-material'
import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { handleDetailModalData, handleDetailModalState } from '../../features/afarizmi/AfarizmiSlice'
import { useDispatch } from 'react-redux'

const Card = ({imgUrl, name, square, buildingName, floor, status, item}) => {
    const dispatch = useDispatch()
    let imgPathName = item.planMetric?.name.split(',');
    let imgPathUrl = item.planMetric?.url.split(',');

  return (
    <Box onClick={() => {
        dispatch(handleDetailModalState(true))
        dispatch(handleDetailModalData(item))
    }} sx={{height: '540px',border: '2px solid #C7C7C7', cursor: 'pointer', ':hover': {backgroundColor: '#ededed', transform: 'translateY(-1px)', }}} borderRadius={3} display={'flex'} flexDirection={'column'} padding={2} >
        <Box>
            <img width={'100%'} height={'227px'} src={imgPathName !== undefined && imgPathUrl !== undefined ? imgPathUrl.at(imgPathName.findIndex(item => item === 'card')) : ''} alt="" loading="lazy"/>
        </Box>
        <Box flex={1} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} mt={2} alignItems={'center'}>
                <Typography fontFamily={'Montserrat'} fontWeight={300} fontSize={'21px'} color={'gray'}>{status}</Typography>
                <Box width={113} height={36} bgcolor={'#333'} color={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Typography textAlign={'center'}>{square}</Typography>
                </Box>
            </Box>
            <Box mt={1}>
                <Typography sx={{ my: 2, mb: 5 }} variant='h4'>{'Lokali: '}{name}</Typography>
                <Box display={'flex'} gap={5} mt={1} justifyContent={'center'}>
                    <Box display={'flex'} gap={1} flexDirection={'column'} alignItems={'center'}>
                        <Apartment sx={{color: '#ff6900'}} />
                        <Typography>{buildingName}</Typography>
                    </Box>
                    <Box display={'flex'} gap={1} flexDirection={'column'} alignItems={'center'}>
                        <TableRows sx={{color: '#ff6900'}} />
                        <Typography>{floor}</Typography>
                    </Box>
                
                <Box display={'flex'} gap={1} flexDirection={'column'} alignItems={'center'}>
                        <OpenWith sx={{color: '#ff6900'}} />
                        <Typography>{square}</Typography>
                    </Box>
                    </Box>
            </Box>
            <Box >
                <Divider sx={{my: 2}} />
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'} gap={1}>
                        <Map />
                        <Typography>Lokacioni</Typography>
                    </Box>
                    <Button sx={{
                        color: 'white',
                        background: '#333',
                        ":hover": {
                            background: '#333A'
                        }
                    }}>Me Shume</Button>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default Card