import React from 'react'
import { getFilterSquareData, handleSquareChange } from '../../../features/filter/FilterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Slider, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function CustomThumb(props) {
    return (
      <span {...props}>
        {props["aria-valuenow"] === "50" ? (
          <ArrowBackIcon fontSize="small" />
        ) : null}
        {props["aria-valuenow"] === "1000" ? (
          <ArrowForwardIcon fontSize="small" />
        ) : null}
      </span>
    );
  }
  

const SliderComponent = () => {
    const dispatch = useDispatch()
    const square = useSelector(getFilterSquareData);
    const handleChangeSquare = (event, newValue) => {
        console.log(newValue)
        dispatch(handleSquareChange(newValue));
      };
  return (
    <div>
        <Slider
            min={77.4}
            max={1558.70}
            sx={{color: '#333'}}
            step={0.99}
            value={[square.startVal, square.endVal]}
            size='small'
            onChange={handleChangeSquare}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => (
            <div style={{display: 'flex', justifyContent: 'center', gap: 2}}>
                {value === square.startVal ? <ArrowForwardIcon fontSize='small'/> : null}
                {value === square.endVal ? <ArrowBackIcon fontSize='small'/> : null}
                {value}
            </div>
            )}
            
      />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{square.startVal.toFixed(2)} m2</Typography>
          <Typography variant="h6">{square.endVal.toFixed(2)} m2</Typography>
       </Box>
    </div>
  )
}

export default SliderComponent