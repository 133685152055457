import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterCheckedData, handleFilter } from '../../../features/filter/FilterSlice';

const FilteredCard = ({ title }) => {
  const dispatch = useDispatch();
  const checkedFilter = useSelector(getFilterCheckedData)
  const handleFilterChange = () => {
    dispatch(handleFilter({ name: title, isChecked: false }))
  }

  return (
    <Box borderRadius={3} bgcolor={'grey'} maxWidth={'fit-content'} px={1} display={'flex'} gap={1} alignItems={'center'} my={1} color={'white'}>
        <Typography variant='body1'>{title}</Typography>
        <Box display={'flex'} alignItems={'center'} sx={{ ':hover': {
            cursor: 'pointer',
            backgroundColor: 'black',
            borderRadius: '50px',
            color: 'white'
        }}}
        onClick={handleFilterChange}
        >
            <Close fontSize='small'/>
        </Box>
    </Box>
  )
}

export default FilteredCard