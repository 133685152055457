import { createSlice } from "@reduxjs/toolkit"
import { getSvgDataAll, updateApartment } from "./AfarizmiApi";

const initialState = {
    filteredCard: '',
    svgData: null,
    msg: '',
    status: 'idle',
    detailModalState: false,
    detailModalData: {},
}

const AfarizmiSlice = createSlice({
    name: 'AfarizmiSlice',
    initialState,
    reducers: {
        resetStatusAndMsg: (state) => {
            state.msg = ''
            state.status = 'idle'
        },
        handleDetailModalState: (state, action) => {
            state.detailModalState = action.payload
        },
        handleDetailModalData: (state, action) => {
            state.detailModalData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSvgDataAll.pending , (state, action) => {
            state.status = 'getAll_pending';
        })
        .addCase(getSvgDataAll.fulfilled , (state, action) => {
            state.status = 'getAll_success';
            state.svgData = action.payload;
        })
        .addCase(getSvgDataAll.rejected , (state, action) => {
            state.msg = action.payload;
            state.status = 'getAll_rejected';
        })
        .addCase(updateApartment.pending , (state, action) => {
            state.status = 'updateApartment_pending'
        })
        .addCase(updateApartment.fulfilled , (state, action) => {
            state.status = 'updateApartment_success'
        })
        .addCase(updateApartment.rejected , (state, action) => {
            state.status = 'updateApartment_rejected'
        })
    }
});
export const getSvgData = (state) => state.AfarizmiSlice.svgData;
export const getAfarizmiStatus = (state) => state.AfarizmiSlice.status;
export const getAfarizmiDetailModalState = (state) => state.AfarizmiSlice.detailModalState;
export const getAfarizmiDetailModalData = (state) => state.AfarizmiSlice.detailModalData;
export const { 
    resetStatusAndMsg,
    handleDetailModalState,
    handleDetailModalData,
} = AfarizmiSlice.actions;
export default AfarizmiSlice.reducer;