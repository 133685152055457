import { createSlice } from "@reduxjs/toolkit"

const initialState = {   
    checkedFilter: [
        {
            name: 'all',
            isChecked: true,
        }
    ],
    square: {
        startVal: 77.4,
        endVal: 1558.70,
    },
    mobileModal: false,

    modalState: {
        open: false,
        anchorEl: null,
    },
    filteredLength: 0,
}

const FilterSlice = createSlice({
    name: 'FilterSlice',
    initialState,
    reducers: {
        handleFilter: (state, action) => {
            const { name, isChecked } = action.payload
            if(state.checkedFilter.find((item) => item.name === name) !== undefined) {
               state.checkedFilter.map((item) => {
                if(item.name === name){
                    item.isChecked = isChecked;
                }
               })
               state.checkedFilter.map((item) => {
                if(item.name === 'all' && item.isChecked === true){
                    item.isChecked = false;

                }
                return
               }) 
            } //if elements exists it just updates ischecked property
            else {
                state.checkedFilter.push({
                    name,
                    isChecked,
                })
                state.checkedFilter.map((item) => {
                    if(item.name === 'all' && item.isChecked === true){
                        item.isChecked = false;
    
                    }
                    return
                   }) 
            }           
            
            //if element not exist it just push to the filter array

            //if clicked 
            let hasObject = false;
            let hasFloor = false;
            let countObj = 0;
            for(const item of state.checkedFilter) {      
                // if(((!item.name.startsWith('Objekt') && countObj > 0) || (item.name.startsWith('Objekt') && item.isChecked === false) 
                // || (item.name === 'all' && item.isChecked === true))
                //     ) {
                //     hasObject = true;
                //     console.log('Conditions: ' + (!item.name.startsWith('Objekt') && countObj > 0) + (item.name.startsWith('Objekt') && item.isChecked === false) 
                //     + (item.name === 'all' && item.isChecked === true))
                //     break;
                // }
                if(((!item.name.startsWith('Sut') || !item.name.startsWith('Kati') || !item.name.startsWith('Bodrum') || !item.name.startsWith('Përdhes')) && countObj > 0) 
                || (item.name === 'all' && item.isChecked === true) ||
                (item.name.startsWith('Sut') || item.name.startsWith('Kati') || item.name.startsWith('Bodrum') || item.name.startsWith('Përdhes')) && item.isChecked === false) {
                    hasFloor = true;
                    break;
                }
                countObj += 1;
            }
            if(hasObject){  
                if(!state.checkedFilter.find((item) => item.name === 'allObj'))
                    {
                        state.checkedFilter.push({
                            name: 'allObj',
                            isChecked: true
                        }) 
                    }
                    else {
                        state.checkedFilter.forEach((item) => {
                            if(item.name === 'allObj' && item.isChecked === false){
                                item.isChecked = true
                            }
                        })
                    }
            }//when floor filter is clicked

            if(hasFloor){
                if(!state.checkedFilter.find((item) => item.name === 'allFloor'))
                    {
                        state.checkedFilter.push({
                            name: 'allFloor',
                            isChecked: true
                        }) 
                    }
                    else {
                        state.checkedFilter.forEach((item) => {
                            if(item.name === 'allFloor' && item.isChecked === false){
                                item.isChecked = true
                            }
                        })
                    }
            }

            state.checkedFilter.forEach((element, i) => {
                if((element.name.startsWith('Objekt') && element.isChecked === true)){
                    state.checkedFilter.forEach((item) => {
                        if(item.name === 'allObj' && item.isChecked === true){
                            item.isChecked = false
                        }
                    })
                }
                else if(element.name === 'all' && element.isChecked === true) {
                    state.checkedFilter.forEach((item) => {
                        if(item.name === 'allObj' && item.isChecked === true){
                            item.isChecked = false
                        }
                    })
                }
            });
            state.checkedFilter.forEach(element => {
                if(((element.name.startsWith('Sut') || element.name.startsWith('Kati') || element.name.startsWith('Bodrum') || element.name.startsWith('Përdhes')) && element.isChecked === true) 
                ){
                    state.checkedFilter.forEach((item) => {
                        if(item.name === 'allFloor' && item.isChecked === true){
                            item.isChecked = false
                        }
                    })
                }
                else if(element.name === 'all' && element.isChecked === true) {
                    state.checkedFilter.forEach((item) => {
                        if(item.name === 'allFloor' && item.isChecked === true){
                            item.isChecked = false
                        }
                    })
                }
            });
            const filteredItemsWithObjektet = state.checkedFilter.filter(item => item.name.startsWith('Objekt'));

            // Check if there is at least one item with 'isChecked' set to true
            const isAnyCheckedObjektet = filteredItemsWithObjektet.some(item => item.isChecked);
            if(!isAnyCheckedObjektet){
                state.checkedFilter.forEach((item) => {
                    if(item.name === 'allFloor' && item.isChecked === true){
                        item.isChecked = false
                    }
                })
            }

            const filteredItemsWithFloor = state.checkedFilter.filter(item => 
                item.name.startsWith('Kat') ||
                item.name.startsWith('Sutere') ||
                item.name.startsWith('Bodrum') ||
                item.name.startsWith('Përdhes')
            );

            // Check if there is at least one item with 'isChecked' set to true
            const isAnyCheckedFloor = filteredItemsWithFloor.some(item => item.isChecked);
            console.log(filteredItemsWithFloor)
            if(isAnyCheckedFloor && !isAnyCheckedObjektet) {
                if(!state.checkedFilter.find((item) => item.name === 'allObj'))
                {
                    state.checkedFilter.push({
                        name: 'allObj',
                        isChecked: true
                    }) 
                }
                else {
                    state.checkedFilter.forEach((item) => {
                        if(item.name === 'allObj' && item.isChecked === false){
                            item.isChecked = true
                        }
                    })
                }
            }
            if(!isAnyCheckedFloor){
                state.checkedFilter.forEach((item) => {
                    if(item.name === 'allObj' && item.isChecked === true){
                        item.isChecked = false
                    }
                })
            }

            // let hasAllObject = false;
            // let countAll = 0;
            // for(const item of state.checkedFilter) {
            //     if((item.name.startsWith('Objekt') === false)) {
            //         hasAllObject = true;
            //         break;
            //     }
            //     countAll += 1;
            // }
            // console.log('Kounti === ' + countAll)
            // console.log('Is true' + hasAllObject)
            // if(hasAllObject){
            //     if(!state.checkedFilter.find((item) => item.name === 'allObj'))
            //         state.checkedFilter.push({
            //             name: 'allObj',
            //             isChecked: true
            //         })
            //         else {
            //             state.checkedFilter.forEach((item) => {
            //                 if(item.name === 'allObj' && item.isChecked === false){
            //                     item.isChecked = true
            //                 }
            //             })
            //         } 
            // }

            let hasCheckedItem = false;
            for(const item of state.checkedFilter){
                if(item.isChecked === true) {
                    hasCheckedItem = true;
                    break;
                }
            }
            if(!hasCheckedItem){
                state.checkedFilter.map((item) => {
                    if(item.name === 'all'){
                        item.isChecked = true;
                    }
                   }) 
            }
        },
        handleResetFilter(state) {
            state.checkedFilter.forEach((item) => {
                if(item.name !== 'all'){
                    item.isChecked = false
                }
                else {
                    item.isChecked = true;
                }
            })
            state.square.endVal = 1558.70;
            state.square.startVal = 77.4
        },
        handleSquareChange(state, action) {
            state.square.startVal = action.payload[0];
            state.square.endVal = action.payload[1];
        },
        handleFilterModalState(state, action) {
            state.modalState.open = action.payload.isOpen;
        },
        handleMobileModal(state, action) {
            state.mobileModal = action.payload;
        },
        handleFilteredLength(state, action) {
            state.filteredLength = action.payload
        }
    }
})
export const {
    handleFilter,
    handleSquareChange,
    handleFilterModalState,
    handleMobileModal,
    handleFilteredLength,
    handleResetFilter,
} = FilterSlice.actions;
export const getFilterCheckedData = (state) => state.FilterSlice.checkedFilter;
export const getFilterSquareData = (state) => state.FilterSlice.square;
export const getFilterMobileModalState = (state) => state.FilterSlice.mobileModal;
export const getFilterModalState = (state) => state.FilterSlice.modalState;
export const getFilterLength = (state) => state.FilterSlice.filteredLength;
export default FilterSlice.reducer;