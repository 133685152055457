import { Box, Divider, Popover, Typography } from '@mui/material'
import React from 'react'

const style = {
  width: '250px',
  height: '380px',
  bgcolor: 'background.paper',
  pointerEvents: 'none',
  borderRadius: '10px',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const textStyle = {
  headerText: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 600,
  },
  bodyText: {
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 400,
    color: '#8B8B8B'
  },
}

const ObjektetPopover = ( {modalState} ) => {
  const { open, title, anchorEl, img, item } = modalState;
  return (
    <Popover
      id="mouse-over-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        pointerEvents: "none",
        "& .MuiPopover-paper": {
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        },
      }}
    >
      <Box sx={{ ...style }}>
        <Box>
          <Box sx={{ backgroundColor: "rgba(164, 253, 91, 0.32)" }}>
            <Box sx={{ px: 1, pt: 1, pb: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
                  {"Lokali: "}
                </Typography>
                <Typography sx={{ fontSize: "24px", fontFamily: "Montserrat" }}>
                  {title}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                position: "absolute",
              }}
            >
              <Divider sx={{ flexGrow: 10, bgcolor: "green", height: 2 }} />
              <Box
                sx={{
                  px: 2,
                  bgcolor: "green",
                  color: "white",
                  position: "absolute",
                  borderRadius: '17px',
                  right: 10,
                }}
              >
                <Typography variant="body1">E Disponueshme</Typography>
              </Box>
              <Divider sx={{ flexGrow: 2, bgcolor: "green", height: 2 }} />
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: 'center',
              gap: 1,
              px: {
                md: 1,
              },
            }}
          >
            <Typography sx={{ ...textStyle.headerText }} variant="body1">
              Siperfaqja:
            </Typography>
            <Typography sx={{ ...textStyle.bodyText }} variant="body1">
              {item.square}{' '}m2
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              px: {
                md: 1,
              },
            }}
          >
            <Typography sx={{ ...textStyle.headerText }} variant="body1">
              Kati:
            </Typography>
            <Typography sx={{ ...textStyle.bodyText }} variant="body1">
              {item.floor}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: 'center',
            pb: 2,
            px: {
              md: 1,
            },
          }}
        >
          <img
            width={"170px"}
            height={'170px'}
            src={img}
          />
        </Box>
      </Box>
    </Popover>
  );
}

export default ObjektetPopover