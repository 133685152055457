import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Afarizmi, Authentication } from './pages'
import './app.css'
import SvgExtractor from './pages/addSvg/SvgExtractor'
import { ToastContainer } from 'react-toastify'

const App = () => {
  return (
    <HashRouter>
      <ToastContainer />
        <Routes>
            <Route path='/' element={<Afarizmi />}/>
            <Route path='/svg-extractor' element={<SvgExtractor />}/>
            <Route path='/ir-admin' element={<Authentication />}/>
        </Routes>
    </HashRouter>
  )
}

export default App