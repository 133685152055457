import { configureStore } from "@reduxjs/toolkit";
import { AfarizmiSlice, ApartmentEditSlice, AuthSlice, FilterSlice, WishListSlice } from "../features";

export const store = configureStore({
  reducer: {
    AuthSlice: AuthSlice,
    WishlistSlice: WishListSlice,
    FilterSlice: FilterSlice,
    AfarizmiSlice: AfarizmiSlice,
    ApartmentEditSlice: ApartmentEditSlice,
  },
});
