import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Modal, Fade, Slider, Typography, useMediaQuery, useTheme, IconButton, Divider, Button } from '@mui/material'
import React, { useState } from 'react'
import FilteredCard from './FilteredCard';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterCheckedData, getFilterMobileModalState, handleFilter, handleMobileModal, handleResetFilter, handleSquareChange } from '../../../features/filter/FilterSlice';
import { Close, Filter1, FilterAlt, KeyboardArrowDown, Refresh } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import SliderComponent from './SliderComponent';
import { mainDarkColor } from '../../../utils/consts';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      PaperProps={{
        sx: {
          width: '100%',
          marginLeft: {
            xs: -2,
            md: 0,
          },        
          marginRight: 5}
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme, widthNumber }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      padding: 20,
      width: widthNumber,
      marginTop: theme.spacing(1),
      minWidth: 150,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
       
      },
    },
  }));

const MobileFilter = () => {
const theme = useTheme();
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const checkedFilter = useSelector(getFilterCheckedData);
  const mobileModal = useSelector(getFilterMobileModalState);
  const [filter, setFilter] = useState({
    'Ne Zhvillim': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Object': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Floor': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Square': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
  })

  const filterLabelStyle = {
    fontSize: isSmallDev ? "18px" : "1.4rem",
    fontFamily: "Montserrat",
    color: 'white'
  };
  const infoTextStyle = {
    fontSize: isSmallDev ? "12px" : "1.1rem",
    fontFamily: "Montserrat",
    color: 'white'
  };
  const handleFilterState = (e, s) => {
    switch(s){
      case 'object':
        setFilter({
          ...filter,
          "Object" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'devState':
        setFilter({
          ...filter,
          "Ne Zhvillim" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'floor':
        setFilter({
          ...filter,
          "Floor" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'square':
        setFilter({
          ...filter,
          "Square" : {
            opened: true,
            anchorEl: e.target,
          }
        })
        break;
    }
  }
  const handleObjectFilter = (name) => {
    if(checkedFilter.length < 1) {
      dispatch(
        handleFilter({
          name: name,
          isChecked: true,
        }));
        return;
    }
    if(!checkedFilter.find((item) => 
      item.name === name
    )) {
      dispatch(
        handleFilter({
          name: name,
          isChecked: true,
        }));
        return;
    }
    checkedFilter.forEach((item) => {
      if(item.name === name) {
        if(item.isChecked){
          dispatch(
            handleFilter({
              name: name,
              isChecked: false,
            }));
            return;
        }
        else {
          dispatch(
            handleFilter({
              name: name,
              isChecked: true,
            }));
            return;
        }
      }
      else {
          return;
      }
    })
   
  }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -30%)",
        width: '95%',
        height: '95%',
        bgcolor: "background.paper",
        boxShadow: 40,
        borderRadius: 5,
        p: 1,
        overflow: 'auto',
        
      };

      const handleChangeSquare = (event, newValue) => {
        dispatch(handleSquareChange(newValue));
      };

      return (
        <>
          <h1></h1>
          <Modal open={mobileModal} closeAfterTransition>
            <Fade in={mobileModal}>
              <Box sx={{ ...style, transition: "opacity 0.9s ease-in-out" }}>
                <Box width={"100%"} overflow={"hidden"}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h6" fontStyle={"Montserrat"}>
                      Filteri
                    </Typography>
                    <IconButton
                      onClick={() => {
                        dispatch(handleMobileModal(false));
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                  <Box
                    height={checkedFilter?.length < 1 && "0px"}
                    display={"flex"}
                    flexWrap={"wrap"}
                    columnGap={1}
                  >
                    {checkedFilter?.map((item, index) => {
                      if (
                        item.isChecked === true &&
                        item.name !== "allFloor" &&
                        item.name !== "allObj"
                      ) {
                        return <FilteredCard key={index} title={item.name} />;
                      }
                    })}
                  </Box>
                  <Grid container mt={1} spacing={isSmallDev ? 1.3 : 3}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "object");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Objekti
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "devState");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Faza Ndertimit
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "floor");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Kati
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "square");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Siperfaqja
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                  </Grid>
                  <StyledMenu
                    id="devState"
                    anchorEl={filter["Ne Zhvillim"].anchorEl}
                    open={filter["Ne Zhvillim"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        "Ne Zhvillim": {
                          ...filter["Ne Zhvillim"],
                          opened: false,
                        },
                      });
                    }}
                  >
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          label="Ne Zhvillim"
                          control={
                            <Checkbox
                              onChange={(e) => {
                                dispatch(
                                  handleFilter({
                                    name: e.target.name,
                                    isChecked: e.target.checked,
                                  })
                                );
                              }}
                              name="Ne Zhvillim"
                              checked={
                                checkedFilter.find(
                                  (item) => item.name === "Ne Zhvillim"
                                )
                                  ? checkedFilter.find(
                                      (item) => item.name === "Ne Zhvillim"
                                    ).isChecked
                                  : false
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </Box>
                  </StyledMenu>
                  <StyledMenu
                    anchorEl={filter["Object"].anchorEl}
                    widthNumber={500}
                    open={filter["Object"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        Object: { ...filter["Object"], opened: false },
                      });
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 500 400"
                      xmlSpace="preserve"
                    >
                      <path
                        className="object-filter"
                        d="M449.7,217.6c0-0.2,0-0.5,0-0.7c0-0.1,0-0.2,0-0.2c0,0,0,0,0-0.1c0,0-0.1-0.2-0.2-0.7c-0.1-0.2-0.1-0.4-0.2-0.6
	c-2.1-6.8-14.8-39.8-63.9-54.4c0,0-183.2-18.4-225.5-17.8c-10.7,0.7-22.6,2.2-35.4,5.4c-33.7,8.3-59.4,23.9-76.7,37.3
	c-0.4,1-1,1.9-1.3,2.9c-0.2,1-0.4,2-0.2,3c0.2,1.2,0.9,2.2,1.5,3.1c0.5,0.7,1.2,1.1,1.8,1.5c13.1,6.2,27.2,12.4,42.4,18.4
	c23.4,9.1,45.5,16.1,65.8,21.6c0,0,34.6,2.9,52.2,13.4c0,0,77,29.7,98.9,44.7c0,0,29.2,12.4,46.9-6.5c0,0,12.2-18,58.9-47.3
	c0,0,29-12.8,34.2-20.8c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.2,0.1-0.4,0.2-0.6c0,0,0-0.1,0-0.1C449.7,217.9,449.7,217.8,449.7,217.6
	C449.7,217.7,449.7,217.6,449.7,217.6z"
                      />
                      <polygon
                        onClick={() => handleObjectFilter("Objekti 4")}
                        className="object-filter-select"
                        points="84,137.1 105.9,128.8 128.5,132.2 128.5,132.7 125.5,134.2 125.8,137.4 132.6,138 133.3,143.4 
	134.9,142.7 141,143.7 141.9,149.1 141.5,149.1 141.9,151.5 140.8,152.3 140.8,152.5 142.5,152.5 142.5,155 141.4,155.8 
	141.4,156.7 142.8,157 142.8,161.3 143.5,161.3 143.5,163.6 135.7,168.9 135.8,174.1 135.2,174.3 135.3,179.7 134.7,180.1 
	135.1,185.3 134.3,185.6 135.1,192.9 128,197.3 131,217.4 105.6,211.1 102.9,209 101.4,208.7 98.6,206 97,197.5 90.9,201.4 
	86.1,198.5 76.4,195.9 73.1,180.4 79.6,177.1 79.3,175.2 79.6,174.6 79.1,171.5 78.1,171.5 77.1,165.9 77.8,165.4 77.1,162.3 
	76.2,162.2 74.9,156.6 75.7,156 74.2,150.2 81.5,146.8 81.2,144.2 86.9,141.7 86.3,141.4 87.8,140.3 87.7,138.5 84,137.9 "
                      />
                      <path
                        onClick={() => handleObjectFilter("Objekti 5")}
                        className="object-filter-select"
                        d="M153.9,148.1l36.6,4.3v1.7l-2.2,2l0.6,8.4l3.3,0.4l0.5,7.7h0.8v1.1l-0.8,0.6l0.1,3.2h1c0,0,2.1,1,2.5,1.5l6,0.4
	l0.4,4.3l4.7,0.5v9l-1.8,2l0.3,4.8l-12.7,14.3l1,19.1l-34-6.2l-4.2-2.7l-2.3-0.2l-4.4-3.2l-18.5-3.7l-3-20.1l7.1-4.3l-0.8-6.9
	l0.8-0.8l-0.5-5.1l0.6-0.4l-0.1-5.4l0.6-0.2l-0.1-5.3l7.8-5.2v-2.4h-0.7V157l-1.5-0.3v-0.9L153.9,148.1z"
                      />
                      <path
                        onClick={() => handleObjectFilter("Objekti 6")}
                        className="object-filter-select"
                        d="M234.3,163.7l-13.2,8.8v0.9l4.3,1.1v4l-2.6,1.5l-0.1,2.3l-3.1-0.3l-5.3,3.1v4.3c0,0-0.9,0.5-0.8,1.7l0,5.7
	c0,0-1.1,0.5-1.1,1.2l0,4.7c0,0-0.9,0.4-0.8,1.1l0.2,5.3l-7.1,5.5v1.8h-0.6l1,19.1l7.4,2.5l0,4.1l73,23.8l12.6-18.8v-9.3l-16.3-9.1
	c0,0-2-1.8-0.6-3.6l4.4-5.3c0,0,1.1-2.2-2.9-3.1l0.6-15.5l-9.5-2.1l0.1-1.4l1.5-1.7v-0.9l-1.5-0.8l0.1-8.5l-3.2-0.9v-8.4l4.3-3.5
	l0-0.9L234.3,163.7z"
                      />
                      <path
                        onClick={() => handleObjectFilter("Objekti 7")}
                        className="object-filter-select"
                        d="M351.3,166.7l-33.8,10.4v1.3l3.8,1.7v1.1l-2.9,0.8l-1.5,17l-1.3-1l-12.5,5.9l-0.3,3.3l-4.9,1.9l-0.3,4.7
	l-7.7,9.3c0,0-2.8,2.7,0,4.2l13.1,7l7.7,9.4l4.5,2.9l4.6,5.6l2.9,1.7l3.5,3.9l1-0.7l9.3,6.3l23.1-11.3l3.7-3.8c0,0,1.1-2.4,3.1-2.1
	l3-2.4l11.3-5.8l2.4-20.7l-5.6-3.3l-0.1-1.1l0.9-0.4l1.1-6.2l-1.4-0.8l0.7-4.9l-1.8-1l0.7-5.4l-1.7-0.8l0.6-5.3l-7-3.6l-0.1-1.5
	l-4-1.9l0.3-2.9l4.7-1.7l0.1-1.1L351.3,166.7z"
                      />
                      <polygon
                        onClick={() => handleObjectFilter("Objekti 8")}
                        className="object-filter-select"
                        points="402.7,155.1 376.5,163.6 376.5,164.4 380,165.9 378.8,173.9 377.1,174.4 376.9,175.6 376.2,175.8 
	375.6,179.8 368.4,182.3 369.7,182.9 369.8,184.4 376.8,188.1 376.3,193.4 377.9,194.2 377.2,199.6 379,200.5 378.3,205.4 
	379.7,206.2 379.4,207.8 396.6,215.8 397.8,215.5 395.1,231.1 422.1,218.2 421.3,217.6 423,209.5 428.3,207.2 428.3,206.3 
	426.1,205.7 426.4,204.1 429,202.9 430.3,197.2 428,196.3 428.1,195.3 428.5,195.1 429.6,189.7 428,188.9 428.9,184.5 427.4,183.6 
	428.2,179.3 426.6,178.1 427.4,173.4 421.7,171.4 422,168.9 417.9,167.4 418.2,164.6 422.9,162.8 422.9,162 "
                      />
                      <path
                        onClick={() => handleObjectFilter("Objekti 1")}
                        className="object-filter-select"
                        d="M346.5,127.8l6.6,8.3v1.1h-2.9l2.1,3.9l-0.2,4.9l5.9,8l-0.3,5.4h-3.2l2.5,3.3l-0.5,5.3h-2.4l-2.9-1.3
	l-33.8,10.4v1.3l3.8,1.7v1.1l-2.9,0.8l-0.8,9.7h-15.8l0.9-15l-4.7-18.1l7,0.1l0.1-1.6l0.6,0l0-1.4l0.4,0l-0.5-1.4l0.2-5.1l0.3,0.1
	l0.1-1.4h0.4l-0.5-1.7l0-5.2l0.8,0l0.1-1c0,0,0.7-0.5,1.3-0.2l1.4-0.1l0.1-3.9l3.9,0.1l0.1-3.1l-2.1-4.3l0-0.7H346.5z"
                      />
                      <polygon
                        onClick={() => handleObjectFilter("Objekti 2")}
                        className="object-filter-select"
                        points="244.5,127.2 279.2,127.2 280.6,135.3 282.7,135.3 283.6,140.4 283.6,145.5 282.7,145.5 282.9,147.8 
	284,147.8 285.2,153.3 285.2,158.6 288.4,158.6 292.4,177.4 291.6,191.8 273.8,192.3 273.9,185.9 270.6,185.1 270.6,176.7 
	274.9,173.1 274.9,172.3 239,164.7 239,162.5 238.5,162.4 238.5,157.3 238.8,155.7 238.8,153.9 238.5,153.7 238.5,148.5 
	241.1,139.4 243.6,139.2 243.7,136.1 243.4,136.1 243.4,135.3 "
                      />
                      <polygon
                        onClick={() => handleObjectFilter("Objekti 3")}
                        className="object-filter-select"
                        points="177.7,122.2 212.1,122.7 212.1,124.4 210.2,128.3 210.2,129.9 213.6,129.9 214.1,134.8 216.9,134.8 
	216.9,136.4 217.3,136.4 217.3,141.1 216.5,143.4 216.6,144.7 217.6,144.7 217.8,149.5 216.9,151.9 217.8,152.3 217.8,155.9 
	220.1,155.9 220.1,155.4 225.7,155.4 225.7,159.9 221.8,172.1 221.1,172.5 221.1,173.4 220.5,173.4 220.5,182.1 219.5,182 
	214.3,185.1 214.3,186.3 207.4,186.3 207.4,184.3 202.8,183.7 202.3,179.4 196.3,179 193.8,177.5 192.8,177.5 192.7,174.3 
	193.5,173.7 193.5,172.6 192.7,172.6 192.2,165 188.9,164.6 188.3,155.9 190.5,154.1 190.5,152.4 166.1,149.5 166.1,147.8 
	166.6,147.8 166.5,146.1 172,139.2 172,131 171.5,131 171.6,130.1 "
                      />
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 98.8904 173.0637)"
                        className="object-filter-text"
                      >
                        4
                      </text>
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 159.1279 188.2633)"
                        className="object-filter-text"
                      >
                        5
                      </text>
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 238.402 213.5275)"
                        className="object-filter-text"
                      >
                        6
                      </text>
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 340.1883 213.5386)"
                        className="object-filter-text"
                      >
                        7
                      </text>
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 402.3247 186.2977)"
                        className="object-filter-text"
                      >
                        8
                      </text>
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 328.0214 155.1278)"
                        className="object-filter-text"
                      >
                        1
                      </text>
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 260.8231 154.2552)"
                        className="object-filter-text"
                      >
                        2
                      </text>
                      <text
                        transform="matrix(0.9998 -1.748733e-02 1.748733e-02 0.9998 187.647 147.424)"
                        className="object-filter-text"
                      >
                        3
                      </text>
                    </svg>
                  </StyledMenu>
                  <StyledMenu
                    anchorEl={filter["Floor"].anchorEl}
                    widthNumber={350}
                    open={filter["Floor"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        Floor: { ...filter["Floor"], opened: false },
                      });
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            label="Përdhesë"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Përdhesë"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Përdhesë"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Përdhesë"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel
                            label="Suteren"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Suteren"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Suteren"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Suteren"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                      </Box>
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            label="Kati 1"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Kati 1"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Kati 1"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Kati 1"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel
                            label="Bodrum"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Bodrum"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Bodrum"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Bodrum"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </StyledMenu>
                  <StyledMenu
                    anchorEl={filter["Square"].anchorEl}
                    widthNumber={350}
                    open={filter["Square"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        Square: { ...filter["Square"], opened: false },
                      });
                    }}
                  >
                    <Typography variant="h6">Siperfaqja</Typography>
                    <SliderComponent />
                  </StyledMenu>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Button
                    onClick={() => {
                      dispatch(handleResetFilter());
                    }}
                    sx={{
                      mt: 2,
                      color: mainDarkColor,
                      borderColor: mainDarkColor,
                      display: {
                        xs: "flex",
                        md: "none",
                      },
                    }}
                    startIcon={<Refresh />}
                    variant="outlined"
                  >
                    Reseto Filterin
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(handleMobileModal(false));
                    }}
                    sx={{
                      mt: 2,
                      color: mainDarkColor,
                      borderColor: mainDarkColor,
                      display: {
                        xs: "flex",
                        md: "none",
                      },
                    }}
                    startIcon={<FilterAlt />}
                    variant="outlined"
                  >
                    Filtro
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </>
      );
}

export default MobileFilter