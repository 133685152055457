import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { getFilterLength } from '../../../features/filter/FilterSlice'
import { Refresh } from '@mui/icons-material'

const FilterResult = () => {
    const filteredDataLength = useSelector(getFilterLength);
    
  
  return (
    <Box sx={{ display: 'flex', mt: 2 }} alignItems={'center'} gap={3}>
        <Box display={'flex'} gap={1}>
         <Typography variant='h6' fontWeight={700}>{('Të lira:').toUpperCase()}</Typography>
         <Typography variant='h6' sx={{color: 'green'}} fontWeight={400}>{
        filteredDataLength 
         }</Typography>
        </Box>
    </Box>
  )
}

export default FilterResult