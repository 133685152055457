import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ObjektetPopover from '../popover/objektetPopover/ObjektetPopover';
import { useDispatch, useSelector } from 'react-redux';
import { getSvgDataAll } from '../../features/afarizmi/AfarizmiApi';
import { getSvgData, handleDetailModalData, handleDetailModalState } from '../../features/afarizmi/AfarizmiSlice';
import ContextMenu from '../contextMenu/ContextMenu';
import ObjectEditModal from '../modal/ObjectEditModal';
import { getApartmentEditModalState } from '../../features/apartmentEdit/ApartmentEditSlice';
import { getFilterCheckedData, getFilterSquareData } from '../../features/filter/FilterSlice';
import FilterResult from '../filter/components/FilterResult';

const SvgHolder = () => {
  const editModalState = useSelector(getApartmentEditModalState)
  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    anchorEl: null,
    img: null,
    id: null,
    item: null,
  });
  const dispatch = useDispatch()
  const svgData = useSelector(getSvgData)
  const [currentIndex, setCurrentIndex] = useState(1)
  const checkedFilter = useSelector(getFilterCheckedData)
  const squareFilter = useSelector(getFilterSquareData)
  const [contextMenu, setContextMenu] = useState({
    anchorEl: null,
    data: null,
    id: null,
    open: false, 
    title: '',
    img: '',
    item: null,
  });

  const handlePrev = () => {
    setCurrentIndex((prevIdx) => Math.max(prevIdx - 1, 0));
    if(currentIndex === 0) {
      setCurrentIndex(svgData.length - 1);
    }
  };
  const handleNext = () => {
    setCurrentIndex((prevIdx) => Math.min(prevIdx + 1, svgData.length - 1));
    if(currentIndex === svgData.length - 1){
      setCurrentIndex(0);
    }
  };
  const handleMouseEnter = (item, e) => {
    setModalState({
      ...modalState,
      open: true,
      anchorEl: e.currentTarget,
      item: item,
      img: item.imageUrl,
      title: item.name,
    })
  };
  const handleMouseLeave = () => {
    setModalState({
      ...modalState,
      open: false,
      anchorEl: null,
      item: null,
    })
  };
  const handleClick = (item) => {
    dispatch(handleDetailModalState(true))
    dispatch(handleDetailModalData(item))
  };
  const handleContextMenu = (item, e) => {
    setContextMenu({
      ...contextMenu,
      anchorEl: e.currentTarget,
      id: item.id,
      data: item,
      open: true,
    })
  };
  
  useEffect(() => {
    dispatch(getSvgDataAll())
  }, [])

 
  return (
    <>
      <Box
        style={{ position: "relative" }}
        sx={{ display: { xs: "none", md: "block" }, height: "100%" }}
      >
        <IconButton
          sx={{
            position: "absolute",
            bottom: "40%",
            left: "-60px",
            border: "2px solid #333",
          }}
          onClick={handlePrev}
        >
          <ArrowBack sx={{ color: "#333" }} fontSize="large" />
        </IconButton>
        <IconButton
          sx={{
            position: "absolute",
            bottom: "40%",
            right: "-60px",
            border: "2px solid #333",
          }}
          onClick={handleNext}
        >
          <ArrowForward sx={{ color: "#333" }} fontSize="large" />
        </IconButton>
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}><FilterResult /> </Box>
        {svgData?.map((svg, index) => (
          <div
            key={svg.buildingName}
            style={{
              height: index === currentIndex ? "100%" : "0",
              opacity: currentIndex === index ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <svg width={"100%"} height={"100%"} viewBox={svg.viewBoxStyle}>
              <image
                width={svg.imgWidth}
                height={svg.imgHeight}
                transform={svg.imgTransform}
                xlinkHref={svg.imgUrl}
              />
              {svg?.apartmentList.map((item, idx) => {
                if (item.pointsType === "path") {
                  return (
                    <path
                      key={item.apartmentId + idx}
                      d={item.path}
                      id={item.isSold ? item.apartmentId : ""}
                      className={
                        !item.isSold && (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                          if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                            return true;
                          }
                        })) && (item.square >= squareFilter.startVal && item.square <= squareFilter.endVal) && 
                        (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                          if(it.name.includes(item.floor) && it.isChecked){
                            return true;
                          }
                        }))
                        || (checkedFilter.find((it) => it.name.includes('allFloor') && it.isChecked) && checkedFilter.some((it) => {
                          if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                            return true;
                          }
                        }))
                        || (checkedFilter.find((it) => it.name.includes('allObj') && it.isChecked) && checkedFilter.some((it) => {
                          if(it.name.includes(item.floor) && it.isChecked){
                            return true;
                          }
                        }))
                         ? "st0" : 'st1'
                      }
                      onMouseEnter={(e) => {
                        if(!item.isSold && (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                          if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                            return true;
                          }
                        })) && (item.square >= squareFilter.startVal && item.square <= squareFilter.endVal) && 
                        (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                          if(it.name.includes(item.floor) && it.isChecked){
                            return true;
                          }
                        })) || (checkedFilter.find((it) => it.name.includes('allFloor') && it.isChecked) && checkedFilter.some((it) => {
                          if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                            return true;
                          }
                        }))
                        || (checkedFilter.find((it) => it.name.includes('allObj') && it.isChecked) && checkedFilter.some((it) => {
                          if(it.name.includes(item.floor) && it.isChecked){
                            return true;
                          }
                        }))) 
                          handleMouseEnter(item, e);
                      }}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => {
                       if(!item.isSold && (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                        if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                          return true;
                        }
                      })) && (item.square >= squareFilter.startVal && item.square <= squareFilter.endVal) && 
                      (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                        if(it.name.includes(item.floor) && it.isChecked){
                          return true;
                        }
                      })) || (checkedFilter.find((it) => it.name.includes('allFloor') && it.isChecked) && checkedFilter.some((it) => {
                        if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                          return true;
                        }
                      }))
                      || (checkedFilter.find((it) => it.name.includes('allObj') && it.isChecked) && checkedFilter.some((it) => {
                        if(it.name.includes(item.floor) && it.isChecked){
                          return true;
                        }
                      }))) 
                        handleClick(item)}}
                      onContextMenu={(e) => handleContextMenu(item, e)}
                    />
                  );
                }
                if (item.pointsType === "polygon") {
                  return (
                    <polygon
                      points={item.path}
                      key={item.apartmentId + idx}
                      id={item.isSold ? item.apartmentId : ""}
                      className="st0"
                      onMouseEnter={(e) => {
                        handleMouseEnter(item, e);
                      }}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleClick(item)}
                      onContextMenu={(e) => handleContextMenu(item, e)}
                    />
                  );
                }
              })}
            </svg>
          </div>
        ))}
      </Box>
      <Box
        style={{ position: "relative" }}
        sx={{ display: { xs: "block", md: "none" }, width: "100%", overflowY: 'hidden' }}
      >
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}><FilterResult /> </Box>
        <Box sx={{ overflowX: "auto", overflowY: 'hidden' }}>
        {svgData?.map((svg, index) => (
          <div
            key={svg.buildingName}
            style={{
              height: index === currentIndex ? "100%" : "0",
              opacity: currentIndex === index ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <svg width={"250%"} height={"100%"} viewBox={svg.viewBoxStyle}>
              <image
                width={svg.imgWidth}
                height={svg.imgHeight}
                transform={svg.imgTransform}
                xlinkHref={svg.imgUrl}
              />
              {svg?.apartmentList.map((item, idx) => {
                if (item.pointsType === "path") {
                  return (
                    <path
                      d={item.path}
                      key={item.apartmentId + idx}
                      id={item.isSold ? item.apartmentId : ""}
                      className={
                        !item.isSold && (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                          if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                            return true;
                          }
                        })) && (item.square >= squareFilter.startVal && item.square <= squareFilter.endVal) && 
                        (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                          if(it.name.includes(item.floor) && it.isChecked){
                            return true;
                          }
                        }))
                        || (checkedFilter.find((it) => it.name.includes('allFloor') && it.isChecked) && checkedFilter.some((it) => {
                          if(it.name.slice(8).includes(item.apartmentNumber) && it.isChecked){
                            return true;
                          }
                        }))
                        || (checkedFilter.find((it) => it.name.includes('allObj') && it.isChecked) && checkedFilter.some((it) => {
                          if(it.name.includes(item.floor) && it.isChecked){
                            return true;
                          }
                        }))
                         ? "st0" : 'st1'
                      }
                      // onMouseEnter={(e) => {
                      //   handleMouseEnter(item, e);
                      // }}
                      // onMouseLeave={handleMouseLeave}
                      onClick={() => handleClick(item)}
                      onContextMenu={(e) => handleContextMenu(item, e)}
                    />
                  );
                }
                if (item.pointsType === "polygon") {
                  return (
                    <polygon
                      points={item.path}
                      key={item.apartmentId + idx}
                      id={item.isSold ? item.apartmentId : ""}
                      className="st0"
                      onMouseEnter={(e) => {
                        handleMouseEnter(item, e);
                      }}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleClick(item)}
                      onContextMenu={(e) => handleContextMenu(item, e)}
                    />
                  );
                }
              })}
            </svg>
          </div>
        ))}
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
        <IconButton
          onClick={handlePrev}
          sx={{
            border: "2px solid #333",
          }}
        >
          <ArrowBack sx={{ color: "#333" }} fontSize="large" />
        </IconButton>
        <IconButton
          onClick={handleNext}
          sx={{
            border: "2px solid #333",
          }}
        >
          <ArrowForward sx={{ color: "#333" }} fontSize="large" />
        </IconButton>
        </Box>
      </Box>
      {contextMenu.open ? (
        <ContextMenu menu={contextMenu} setMenu={setContextMenu} />
      ) : null}
      {modalState.open ? (
        <ObjektetPopover
          key={modalState.anchorEl + modalState.title}
          modalState={modalState}
          setModalState={setModalState}
        />
      ) : null} 
     {editModalState.open ? <ObjectEditModal /> : null}
    </>
  );
}

export default SvgHolder