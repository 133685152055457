import { Close } from '@mui/icons-material'
import { Box, Button, Fade, IconButton, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkIfIsInWishList, getWishListItems, handleRefreshWishListState, handleWishlistItem } from '../../features/wishList/WishListSlice'
import { getAfarizmiDetailModalData, getAfarizmiDetailModalState, getSvgData, handleDetailModalState } from '../../features/afarizmi/AfarizmiSlice'
import InfoCard from './components/InfoCard'
import { useNavigate } from 'react-router-dom'
import { getFilterCheckedData, getFilterSquareData } from '../../features/filter/FilterSlice'

const ObjectDetailModal = () => {
    const [isWishList, setWishList] = useState(false);
    const wishListItems = useSelector(getWishListItems);
    const data = useSelector(getAfarizmiDetailModalData);
    const checkedFilter = useSelector(getFilterCheckedData)
    
  const squareFilter = useSelector(getFilterSquareData)
    const theme = useTheme()
    const open = useSelector(getAfarizmiDetailModalState)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isSmallDev = useMediaQuery(theme.breakpoints.down("md"));
    const svgData = useSelector(getSvgData)
    const [planMetricNames, setPlanMetricNames] = useState([])
    const [planMetricUrls, setPlanMetricUrls] = useState([])
    const { id } = data;

  const handleWishList = () => {
    if(checkIfIsInWishList(id)) {
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      const filteredList = storedArray.filter((item) => item !== id)
      
      localStorage.setItem('wish', JSON.stringify(filteredList))
      setWishList(false)
      dispatch(handleWishlistItem(wishListItems.filter((item) => item !== id)))
    }
    }
    else{
      const storedArray = JSON.parse(localStorage.getItem('wish')) || [];
    if(storedArray?.length > 0){
      storedArray.push(id)
      
      localStorage.setItem('wish', JSON.stringify(storedArray))
    }
    else{
      const wishListToAdd = [
        id
      ]
      localStorage.setItem('wish', JSON.stringify(wishListToAdd))
    }
    setWishList(true)
    let wishToAdd = [...wishListItems];
    wishToAdd.push(id)
    dispatch(handleWishlistItem(wishToAdd))
    }
    dispatch(handleRefreshWishListState())
  }
  useEffect(() => {
     if(checkIfIsInWishList(id)){
      setWishList(true)
     }
  }, [])
  useEffect(() => {
    setPlanMetricNames(data.planMetric?.name.split(','))
    setPlanMetricUrls(data.planMetric?.url.split(','))
 }, [data])

  const renderObject = () => {
    return svgData?.map((svg) => {
      return svg.apartmentList.map((apartment) => {
        if(apartment.path === data.path){
          return <div
          key={svg.buildingName}
          style={{
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <svg width={"100%"} height={"100%"} viewBox={svg.viewBoxStyle}>
            <image
              width={svg.imgWidth}
              height={svg.imgHeight}
              transform={svg.imgTransform}
              xlinkHref={svg.imgUrl}
            />
            {svg?.apartmentList.map((item, idx) => {
              if (item.pointsType === "path") {
                return (
                  <path
                    key={item.apartmentId + idx}
                    d={item.path}
                    id={item.isSold ? item.apartmentId : ""}
                    className={item.path === data.path ? "st0" : "st1"}
                  />
                );
              }
              if (item.pointsType === "polygon") {
                return (
                  <polygon
                    points={item.path}
                    key={item.apartmentId + idx}
                    id={item.isSold ? item.apartmentId : ""}
                    className="st0"
                  />
                );
              }
            })}
          </svg>
        </div>
        }
      })
    })
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallDev ? '95%' : '90%',
    height: isSmallDev ? '100%' : '90%',
    bgcolor: "background.paper",
    boxShadow: 40,
    borderRadius: 5,
    p: isSmallDev ? 2 : 0,
    overflow: 'auto',
    
  };
  return (
    <Modal open={open} closeAfterTransition>
      <Fade in={open}>
        <Box sx={{ ...style, transition: "opacity 0.9s ease-in-out" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 1,
              mt: {
                xs: 2,
                md: 2,
              },
              mb: 2,
            }}
          >
            <Box sx={{
              display: {
                xs: 'none',
                md: 'block'
              }
            }} flex={6}></Box>
            <Box flex={6} display={"flex"} justifyContent={"space-between"}>
              <Box
                sx={{
                  ml: {
                    md: 10,
                    xs: 1,
                  },
                  mt: 2,
                  px: {
                    md: 2
                  },
                }}
              >
                <Typography fontWeight={"bold"} variant="h4">
                  {"Lokali: ".toUpperCase()}
                  {data.name}
                </Typography>
              </Box>
              <IconButton
                onClick={() => dispatch(handleDetailModalState(false))}
              >
                <Close fontSize="large" />
              </IconButton>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={isSmallDev ? "column" : "row"}
            paddingX={2}
            height={"100%"}
            marginTop={"-30px"}
          >
            <Box
              flex={5}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={"90%"}
              sx={{
                ml: {
                  md: 3,
                },
              }}
            >
              <Box
                flexDirection={"column"}
                justifyContent={"center"}
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  height: "100%",
                }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  alt="Planimetria"
                  title="Planimetria"
                  style={{ objectFit: "cover", marginTop: 5 }}
                  src={data.imageUrl}
                />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                  },
                  my: 3,
                }}
              >
                <img
                  width={"100%"}
                  height={"100%"}
                  alt="Planimetria"
                  title="Planimetria"
                  src={data.imageUrl}
                />
              </Box>
            </Box>
            <Box
              flex={5}
              display={"flex"}
              justifyContent={"center"}
              alignItems={'center'}
              flexDirection={"column"}
              gap={5}
            >
              <Box
                display={"flex"}
                width={'80%'}
                flexDirection={"row"}
                sx={{
                  gap: {
                    xs: 5,
                    md: 10,
                  },
                  justifyContent: {
                    xs: "space-between",
                  },
                }}
              >
                <InfoCard
                  title={"Objekti"}
                  value={data.apartmentNumber}
                  image={
                    "https://afarizmi.international-residence.com/apartmentImg/imageIcons/Untitled-design-11.png"
                  }
                />
                <InfoCard
                  title={"Numer"}
                  value={data.name}
                  image={
                    "https://afarizmi.international-residence.com/apartmentImg/imageIcons/keys-1.png"
                  }
                />
                <InfoCard
                  title={"Kati"}
                  value={data.floor}
                  image={
                    "https://afarizmi.international-residence.com/apartmentImg/imageIcons/number-1-1.png"
                  }
                />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                width={'80%'}
                sx={{
                  gap: {
                    xs: 5,
                    md: 10,
                  },
                  justifyContent: {
                    xs: "space-between",
                  },
                }}
              >
                <InfoCard
                  title={"Siperfaqja"}
                  value={data.square?.toFixed(2) + " m2"}
                  image={
                    "https://afarizmi.international-residence.com/apartmentImg/imageIcons/neto-1.png"
                  }
                />
                <InfoCard
                  title={"V.Perfundimit"}
                  value={""}
                  image={
                    "https://afarizmi.international-residence.com/apartmentImg/imageIcons/Untitled-design-10.png"
                  }
                />
                <InfoCard
                  title={"F. Zhvillimit"}
                  value={"Ne Ndertim"}
                  image={
                    "https://afarizmi.international-residence.com/apartmentImg/imageIcons/Untitled-design-12.png"
                  }
                />
              </Box>
              <Box
                width={"95%"}
                display={"flex"}
                sx={{
                  gap: {
                    xs: 1,
                    md: 2,
                  },
                  justifyContent: {
                    xs: "space-between",
                    md: "center",
                  },
                  ml: {
                    md: 2,
                  },
                  mb: {
                    xs: 3,
                    md: 0,
                  },
                  mt: {
                    md: 2,
            
                  }
                }}
              >
                <Button
                  onClick={() => {
                    window.open(
                      planMetricUrls.at(
                        planMetricNames.findIndex((it) => it.includes("pdf"))
                      ),
                      "_blank"
                    );
                  }}
                  sx={{
                    color: "#F09133",
                    backgroundColor: "#1D2934",
                    fontWeight: 600,
                    width: {
                      xs: "50%",
                      md: "40%",
                    },
                    ":hover": {
                      color: "#F09133",
                      backgroundColor: "#1D2934",
                    },
                  }}
                  variant="contained"
                >
                  Shkarko PDF
                </Button>
                <Button
                  sx={{
                    color: "#1D2934",
                    backgroundColor: "#F09133",
                    fontWeight: 600,
                    width: {
                      xs: "50%",
                      md: "40%",
                    },
                    ":hover": {
                      color: "#1D2934",
                      backgroundColor: "#F09133",
                    },
                  }}
                  variant="contained"
                >
                  Na kontaktoni
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              mt: {
                xs: 15,
                lg: -1
              },
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mb: 2,
              gap: 3,
            }}
          >
            <Box sx={{
              width: {
                xs: '100%',
                md: '75%'
              },
            }}>
              {
                renderObject()
              }
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ObjectDetailModal