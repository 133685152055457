import React, { useState } from 'react'
import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import GridView from './components/GridView';
import { Filter, Navbar, SvgHolder } from '../../components';
import PageLoading from '../../components/common/PageLoading';
import { useDispatch, useSelector } from 'react-redux';
import { getAfarizmiStatus } from '../../features/afarizmi/AfarizmiSlice';
import ObjectDetailModal from '../../components/modal/ObjectDetailModal';
import ScrollToTopButton from '../../components/common/ScrollToTopButton';
import TableView from './components/TableView';
import { handleMobileModal, handleResetFilter } from '../../features/filter/FilterSlice';
import { Refresh } from '@mui/icons-material';
import { mainDarkColor } from '../../utils/consts';


const Afarizmi = () => {
  const [viewModel, setViewModel] = useState('grid')
  const dispatch = useDispatch()
  const theme = useTheme()
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'))
  const status = useSelector(getAfarizmiStatus)
  const loadingSettings =
    status === "getAll_pending"
      ? { pointerEvents: "none", userSelect: "none", overflow: "hidden" }
      : null;

  return (
    <Box sx={{ ...loadingSettings }}>
      <Navbar />
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        sx={{ userSelect: "none", mt: { xs: 5, md: 8 } }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ width: { xs: "100%", md: "100%" } }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "85%",
              },
              height: {
                md: "650px",
              },
            }}
          >
            <SvgHolder />
          </Box>
          <Box width={"85%"} sx={{display: {
                  xs: "none",
                  md: "flex",
                }, justifyContent: 'flex-end',
                mt: {
                  md: 10,
                  xs: 2
                }}}>
            <Button
              onClick={() => {
                dispatch(handleResetFilter())
              }}
              sx={{
                color: "#333",
                borderColor: "#333",
              }}
              startIcon={<Refresh />}
              variant="outlined"
            >
              Reseto Filterin
            </Button>
          </Box>
          <Box width={"85%"} sx={{mt: {md: 2}}}>
            <Filter />
          </Box>
          <Box width={"85%"} sx={{mt: {md: 5}}}>
            <Box
              width={"100%"}
              gap={2}
              display={"flex"}
              justifyContent={"end"}
              mb={2}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setViewModel("grid");
                }}
                sx={{
                  color: "white",
                  background: mainDarkColor,
                  ":hover": {
                    background: "#333A",
                  },
                }}
              >
                Grid View
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setViewModel("table");
                }}
                sx={{
                  color: "white",
                  background: mainDarkColor,
                  ":hover": {
                    background: "#333A",
                  },
                }}
              >
                Table View
              </Button>
            </Box>
            {viewModel === "grid" && <GridView />}
            {viewModel === "table" && <TableView />}
          </Box>
        </Box>
      </Box>
      {status === "getAll_pending" ? <PageLoading /> : null}
      {isSmallDev && (
        <Box
          sx={{
            position: "sticky",
            bottom: 5,
            width: "95%",
            p: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{ width: "50%", backgroundColor: mainDarkColor, color: 'white', ":hover": {backgroundColor: '#333'} }}
            variant="contained"
            onClick={() => {
              dispatch(handleMobileModal(true));
            }}
          >
            Filteri
          </Button>
        </Box>
      )}
      <ObjectDetailModal />
      <ScrollToTopButton />
    </Box>
  );
}

export default Afarizmi