import { ArrowUpward } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add a scroll event listener to determine when to show the button
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
   <IconButton
    
   sx={{
    display: isVisible ? 'block' : 'none',
    position: 'fixed',
    bottom: 10,
    color: 'white',
    right: 5,
    borderRadius: '50%',
    background: 'rgb(0,0,141)',
background: 'linear-gradient(90deg, rgba(0,0,141,1) 0%, rgba(2,180,217,1) 100%)',
    px: 1.5,
    boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px'
   }} onClick={scrollToTop}>
    <ArrowUpward fontSize='large'/>
   </IconButton>
  );
}

export default ScrollToTopButton;
